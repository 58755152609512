// import

import {styled} from '@linaria/react';
import {darken} from 'polished';

import {useTheme} from 'modules/Theme';

// types

type Props = {
  id: string;
  color?: string;
  flip?: boolean;
  className?: string;
  colors?: [string, string, string, string, string];
  layout?: 'waves' | 'zigzag';
};

// fns

const getZigZag = (y = 0, w = 64, h = 32) => {
  return `
    M ${0}     ${h + y} 
    L ${w / 2} ${y} 
    L ${w}     ${h + y} 
    L ${w}     ${56} 
    L ${0}     ${56} 
    Z
  `.replace(/\s+/gu, ' ').trim();
};

const getWaves = (y = 0, w = 64, h = 32) => {
  return `
    M ${0}        ${h + y} 
    C ${w * 0.25} ${h + y}, 
      ${w * 0.25} ${y},
      ${w * 0.5}  ${y} 
    S ${w * 0.75} ${h + y}, 
      ${w}        ${h + y} 
    L ${w}        ${56} 
    L ${0}        ${56} 
    Z
  `.replace(/\s+/gu, ' ').trim();
};

// component

export function ZigZag(props: Props) {
  const {id, color = 'white', flip, colors, layout = 'zigzag', ...rest} = props;
  const theme = useTheme();
  const colorPick = theme.color[color] ?? color;
  const getPath = layout === 'waves' ? getWaves : getZigZag;

  return (
    <Styled
      {...rest}
      style={{
        '--c0': colors?.[0] ?? colorPick,
        '--c1': colors?.[1] ?? darken(0.1, colorPick),
        '--c2': colors?.[2] ?? darken(0.25, colorPick),
        '--c3': colors?.[3] ?? darken(0.4, colorPick),
        '--c4': colors?.[4] ?? 'var(--white)',
      }}>
      <defs>
        <pattern
          height="56"
          id={id}
          patternTransform={flip ? 'rotate(180)' : undefined}
          patternUnits="userSpaceOnUse"
          width="64">
          <rect className="c4" height="56" width="64" />
          <path className="c3" d={getPath(0)} />
          <path className="c2" d={getPath(4)} />
          <path className="c1" d={getPath(12)} />
          <path className="c0" d={getPath(24)} />
        </pattern>
      </defs>

      <rect fill={`url(#${id})`} height="100%" width="100%" x="0" y="0" />
    </Styled>
  );
}

// styles

const Styled = styled('svg')`
width: 100%;
height: 3.5rem;
display: block;

.c0 { fill: var(--c0); }
.c1 { fill: var(--c1); }
.c2 { fill: var(--c2); }
.c3 { fill: var(--c3); }
.c4 { fill: var(--c4); }
`;
