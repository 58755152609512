// import

import type {MouseEventHandler, PropsWithRef} from 'react';

import {styled} from '@linaria/react';

import {cram} from '@arc/rambo';

// types

type Props = Parameters<typeof Button>[0] & PropsWithRef<{
  open?: boolean;
  onClick?: MouseEventHandler;
}>;

// component

export function BurgerButton(props: Props) {
  const {open, onClick, className, ...rest} = props;

  return (
    <Button
      {...rest}
      aria-expanded={open}
      aria-label="Toggle menu"
      className={cram(className, {open})}
      type="button"
      onClick={onClick}>
      <span className="l1" />
      <span className="l2" />
      <span className="l3" />
    </Button>
  );
}

// styles

const Button = styled('button')`
display: flex;
flex-flow: column;
align-items: center;
justify-content: space-around;

width: 2rem;
height: 2rem;
padding: 0;

background: transparent;
border: none;

> span {
  position: relative;
  border-radius: 0.25rem;
  width: 2rem;
  height: 0.25rem;
  background-color: var(--black);
  transform-origin: center center;
  transition:
    background 100ms ease-in-out,
    transform 100ms ease-in-out;
}

&:hover {
  > span { background-color: var(--red); }
}

&.open {
  .l1 { transform: translateY(0.6875rem) rotate(-45deg); }
  .l2 { transform: scaleY(0); }
  .l3 { transform: translateY(-0.6875rem) rotate(45deg); }
}
`;
