// import

import type {MouseEventHandler, PropsWithChildren, RefObject} from 'react';

import {styled} from '@linaria/react';
import {useCallback} from 'react';
import {useLatest} from 'react-use';

// types

export type FrameProps = PropsWithChildren<{
  frameRef: RefObject<HTMLElement>;
  close?: (...args: unknown[]) => void;
  clickAway?: boolean;
}>;

// component

export function ModalFrame(props: FrameProps) {
  const {children, frameRef, close, clickAway = true, ...rest} = props;

  const onClickRef = useLatest({close, clickAway});
  const onClick: MouseEventHandler<HTMLElement> = useCallback((e) => {
    if (onClickRef.current.clickAway && e.target === frameRef.current) {
      onClickRef.current.close?.();
    }
  }, []);

  return (
    <Frame {...rest} ref={frameRef} onClick={onClick}>
      {children}
    </Frame>
  );
}

// styles

const Frame = styled('aside')`
z-index: var(--z-modals);
position: fixed;
top: 0;
left: 0;
width: 100vw;
height: 100vh;
animation: anim 250ms linear;

@keyframes anim {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
`;
