// import

import type {LicenseKey} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {styled} from '@linaria/react';
import {getYear} from 'date-fns';
import {useRouter} from 'next/router';
import * as R from 'ramda';

import {useSettings} from 'modules/App';
import {Link} from 'modules/Link';

// types

type Props = ComponentProps<typeof Grid>;
type Licenses = Record<string, LicenseKey>;

// vars

const ccLics = {
  'cc-by': {
    name: 'Creative Commons Attribution',
  },
  'cc-by-sa': {
    name: 'Creative Commons Attribution-&zwnj;ShareAlike',
  },
  'cc-by-nd': {
    name: 'Creative Commons Attribution-&zwnj;NoDerivs',
  },
  'cc-by-nc': {
    name: 'Creative Commons Attribution-&zwnj;NonCommercial',
  },
  'cc-by-nc-sa': {
    name: 'Creative Commons Attribution-&zwnj;NonCommercial-&zwnj;ShareAlike',
  },
  'cc-by-nc-nd': {
    name: 'Creative Commons Attribution-&zwnj;NonCommercial-&zwnj;NoDerivs',
  },
};

// fns

const pickLicense = (loc: string, def: LicenseKey, lics: Licenses) => {
  return R.toPairs(lics).reduce<LicenseKey>((acc, [path, license]) => {
    return loc.startsWith('/blog') && path === 'post' ? license :
      loc.startsWith('/support') && path === 'support' ? license :
      loc.startsWith(`/${path}`) ? license : acc;
  }, def);
};

// component

export function AppFooterLegal(props: Props) {
  const {...rest} = props;
  const router = useRouter();
  const {biz: {legalName, licenses}} = useSettings();

  const license = pickLicense(router.pathname, licenses.default, licenses);
  const licenseEls = (
    license === 'all' ? (
      <>All rights reserved.</>
    ) : license === 'cc-zero' ? (
      <><Link href="http://creativecommons.org/publicdomain/zero/1.0/" rel="license">No rights reserved</Link>. To the extent possible under law and unless otherwise noted, <Link href="/">{legalName}</Link> waives all copyright and related or neighboring rights to the contents of this webpage. This work is published from the United States.</>
    ) : (
      <>Except otherwise noted, the contents of this webpage is licensed under a <Link href={`http://creativecommons.org/licenses/${license.slice(3)}/4.0/`} rel="license"><span dangerouslySetInnerHTML={{__html: ccLics[license].name}} /> 4.0 International License</Link>.</>
    )
  );

  return (
    <Grid {...rest}>
      <div className="copy">
        &copy;{getYear(new Date())} <Link href="/">{legalName}</Link>. {licenseEls}
      </div>
    </Grid>
  );
}

// styles

const Grid = styled('div')`
display: grid;
gap: 1.5rem;
justify-content: stretch;
align-items: start;
font-size: 0.875rem;
line-height: 1rem;
color: var(--white);
margin-inline: auto;
max-width: var(--bp-m);
grid:
  [row1-start] "copy" 1fr [row1-end]
  [row2-start] "nav" 1fr [row2-end];

a {
  &:hover {
    text-decoration: underline;
  }
}

.copy {
  grid-area: copy;
  text-align: justify;
  overflow-wrap: break-word;
  hyphens: auto;
}
`;
