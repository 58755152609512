// import

import type {ReactNode} from 'react';

import {styled} from '@linaria/react';

import {useAuth} from './useAuth';

// types

type Props = {children: ReactNode};

// component

export function PageAuth(props: Props): JSX.Element {
  const {children} = props;
  const {error, isLoading} = useAuth();

  if (error) {
    return (
      <Block>
        <div className="inner">{error?.message}</div>
      </Block>
    );
  }

  if (isLoading) {
    return (
      <Block>
        <div className="inner">Logging you in...</div>
      </Block>
    );
  }

  return children as JSX.Element;
}

// styles

const Block = styled('main')`
contain: strict;
z-index: var(--z-auth);
position: fixed;
top: 0;
left: 0;
right: 0;
bottom: 0;
background-color: var(--gray-20);
display: flex;
justify-content: center;
align-items: center;

.inner {
  max-width: var(--bp-m);
  color: var(--white);
  font-size: 3rem;
  line-height: 3rem;
  text-align: center;
}
`;
