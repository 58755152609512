// import

import type {ComponentProps} from 'react';

import {css} from '@linaria/core';
import {useRouter} from 'next/router';
import {useRef, useState} from 'react';
import {useForm} from 'react-hook-form';

import {isClient} from '@arc/env';
import {cram} from '@arc/rambo';

import {hubApi} from 'engines/hubApi';
import {usePostMessage} from 'modules/MessageList';
import {Type} from 'modules/Type';

// types

type Props = ComponentProps<'form'> & {
  onSubmit?: (values: Fields) => void;
  defaultValues?: Fields;
};

export type Fields = {
  name: string;
  email: string;
  message: string;
};

// component

export function ContactForm(props: Props) {
  const {onSubmit: onSubmitLocal, defaultValues, className, ...rest} = props;
  const router = useRouter();
  const [isDisabled, setDisabled] = useState(false);
  const formRef = useRef<HTMLFormElement | null>(null);
  const form = useForm<Fields>({
    defaultValues,
    shouldFocusError: true,
  });
  const postMessage = usePostMessage();

  const onSubmit = form.handleSubmit(async (values: Fields) => {
    try {
      setDisabled(true);
      await hubApi('/email/contact', {
        method: 'POST',
        body: {
          ...values,
          target: router.asPath.startsWith('/support') ?
            'support' : 'contact',
          href: isClient ? location.href : null,
          title: isClient ? document.title : null,
        },
      });

      postMessage({
        color: 'green',
        text: 'Message sent!',
      });

      formRef.current?.blur();
      onSubmitLocal?.(values);
      form.reset();
    } catch (err) {
      console.error(err);

      postMessage({
        color: 'red',
        text: 'Something went wrong! Please try again.',
      });
    } finally {
      setDisabled(false);
    }
  });

  return (
    <form
      {...rest}
      ref={formRef}
      className={cram(formCls, className)}
      onSubmit={onSubmit}>
      <Type className={typeCls}>
        <label htmlFor="name">What's your name?</label>
        <input
          {...form.register('name', {required: true})}
          className={cram({invalid: !!form.formState.errors.name})}
          disabled={isDisabled}
          type="text" />

        <label htmlFor="email">What's your email?</label>
        <input
          {...form.register('email', {required: true})}
          className={cram({invalid: !!form.formState.errors.email})}
          disabled={isDisabled}
          type="email" />

        <label htmlFor="message">How can we help?</label>
        <textarea
          {...form.register('message', {required: true})}
          className={cram(
            textareaCls,
            {invalid: !!form.formState.errors.message},
          )}
          disabled={isDisabled} />

        <button className="teal" disabled={isDisabled} type="submit">
          Send
        </button>
      </Type>
    </form>
  );
}

// styles

const formCls = css`
background: var(--white);
`;

const typeCls = css`
display: flex;
flex-direction: column;
`;

const textareaCls = css`
max-height: calc(100vh - 28.25rem);
min-height: 15vh;
`;
