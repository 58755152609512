// import

import {DefaultSeo, LogoJsonLd, SocialProfileJsonLd} from 'next-seo';

import {useSettings} from 'modules/App/useSettings';

// component

export function AppSeo() {
  const {
    seo: {title, slogan, sep},
    social, socialUrls,
    biz, env, openGraphLogo,
  } = useSettings();

  return (
    <>
      <DefaultSeo
        defaultTitle={`${title} ${sep} ${slogan}`}
        facebook={{
          appId: process.env.FACEBOOK_APP_ID,
        }}
        openGraph={{
          type: 'website',
          site_name: title,
          images: [openGraphLogo],
        }}
        twitter={{
          site: social.twitter,
          handle: social.twitter,
          cardType: 'summary_large_image',
        }} />

      <LogoJsonLd
        logo={biz.logoImage}
        url={env.siteUrl} />

      <SocialProfileJsonLd
        name={biz.niceName}
        sameAs={[
          socialUrls.facebook,
          socialUrls.twitter,
          socialUrls.instagram,
          socialUrls.pinterest,
          socialUrls.reddit,
          socialUrls.youtube,
          socialUrls.linkedin,
        ].filter(Boolean)}
        type="Organization"
        url={env.siteUrl} />
    </>
  );
}
