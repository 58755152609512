/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/anchor-has-content */

// import

import type {LinkProps} from 'next/link';
import type {AnchorHTMLAttributes, KeyboardEventHandler, MouseEventHandler} from 'react';

import NextLink from 'next/link';
import * as R from 'ramda';

import {isStr, uniqueItems} from '@arc/rambo';

// types

type AProps = AnchorHTMLAttributes<HTMLAnchorElement>;
type ExternalProps = AProps;
type DownloadProps = AProps & {download: string | true; href: string};
type NextProps = AProps & LinkProps;

type ButtonProps = AProps & {
  onClick: MouseEventHandler;
  onKeyDown?: KeyboardEventHandler;
  href: never;
  to: never;
};

type Props = ButtonProps | DownloadProps | ExternalProps | NextProps;

// vars

const spaceRx = /\s+/gu;

const nextPropKeys = [
  'as',
  'replace',
  'scroll',
  'shallow',
  'locale',
  'prefetch',
  'href',
] as const;
type NextPropKey = typeof nextPropKeys[number];

// fns

const isDownloadLink = (props: Props): props is DownloadProps =>
  !!props.href && typeof props.download === 'string';

const downloadFile = async (href: string, download: string) => {
  const blob = await fetch(href).then(async (r) => r.blob());
  const a = document.createElement('a');

  a.style.display = 'none';
  a.href = window.URL.createObjectURL(blob);
  a.setAttribute('download', download);

  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(a.href);
  document.body.removeChild(a);
};

const isNextLink = (props: Props): props is NextProps =>
  !!props.href?.startsWith('/');

const isNextProp = (key: string): key is NextPropKey =>
  nextPropKeys.includes(key as NextPropKey);

const getNextProps = (props: Props) =>
  R.toPairs(props).reduce<[Partial<LinkProps>, AProps]>((acc, [key, val]) => {
    if (isNextProp(key)) {
      acc[0][key] = val;
    } else {
      acc[1][key] = val;
    }

    return acc;
  }, [{}, {}]) as [LinkProps, AProps];

const isButtonLink = (props: Props): props is ButtonProps =>
  !props.href;

const getExternalRel = (rel: string[] | string = []) => {
  if (isStr(rel)) {
    rel = rel.trim()
      .toLowerCase()
      .split(spaceRx)
      .filter(Boolean);
  }

  return uniqueItems(rel.concat('noopener')).join(' ');
};

// component

export function Link(props: Props) {
  const {className, style, rel, href} = props;

  if (isDownloadLink(props)) {
    const download = isStr(props.download) ?
      props.download :
      props.href.split('/').pop() as string;
    const onClick: MouseEventHandler<HTMLAnchorElement> = (e) => {
      e.preventDefault();
      downloadFile(props.href, download);
      props.onClick?.(e);
    };

    return (
      <a
        tabIndex={0}
        {...props}
        className={className}
        download={download}
        role="button"
        style={style}
        // eslint-disable-next-line react/jsx-no-bind
        onClick={onClick}
        onKeyDown={onClick as unknown as KeyboardEventHandler} />
    );
  }

  if (isNextLink(props)) {
    const [nextProps, linkProps] = getNextProps(props);

    return (
      <NextLink {...nextProps} passHref>
        <a
          {...linkProps}
          className={className}
          style={style} />
      </NextLink>
    );
  }

  if (isButtonLink(props)) {
    const {onClick, onKeyDown, ...linkProps} = props;

    return (
      <a
        tabIndex={0}
        {...linkProps}
        className={className}
        role="button"
        style={style}
        onClick={onClick}
        onKeyDown={onKeyDown ?? onClick as unknown as KeyboardEventHandler} />
    );
  }

  return (
    <a
      target={href?.startsWith('#') ? undefined : '_blank'}
      {...props}
      className={className}
      rel={getExternalRel(rel)}
      style={style} />
  );
}
