// import

import type {Settings} from 'libs/model';

import constate from 'constate';
import useSWR from 'swr';

import {hubApi} from 'engines/hubApi';

// types

type Props = {
  value: Settings;
};

// context

const SettingsContext = ({value: fallbackData}: Props) => {
  return useSWR('/db/settings', async () => hubApi<Settings>('/settings'), {
    fallbackData,
    revalidateOnMount: true,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
  }).data as Settings;
};

export const [
  SettingsProvider,
  useSettings,
] = constate(SettingsContext);
