// import

import {isClient} from '@arc/env';

// export

export function scrollTo(left = 0, top = 0, smooth = true) {
  if (isClient) {
    window.scrollTo({left, top, behavior: smooth ? 'smooth' : 'auto'});
  }
}
