// import

import type {ReactElement} from 'react';

import {AppContent, AppTickets, AppHeader} from 'modules/App';

import {AppFooter} from './AppFooter';

// types

export type WithLayout = (page: ReactElement) => ReactElement;
type CreateWithLayout = (opts?: LayoutOpts) => WithLayout;

type LayoutOpts = {
  showHeader?: boolean;
};

// export

export const getNoLayout: CreateWithLayout =
  () => (page) => page;

export const getDefaultLayout: CreateWithLayout =
    ({showHeader = true}: LayoutOpts = {}) => (page) => (
      <>
        <AppHeader show={showHeader} />
        <AppTickets />

        <AppContent>
          {page}
        </AppContent>

        <AppFooter />
      </>
    );
