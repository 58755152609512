// import

import type {ComponentProps} from 'react';

import {faAnglesUp, faMessages} from '@fortawesome/pro-regular-svg-icons';
import {styled} from '@linaria/react';
import {useCallback} from 'react';

import {useWindowScroll, useIsMount} from '@arc/react-hooks';

import {scrollTo} from 'libs/browser';
import {useContactForm} from 'modules/Contact';
import {useRouter} from 'modules/Router';

import {AppTicket as Ticket} from './AppTicket';

// component

export function AppTickets(props: ComponentProps<typeof Tickets>) {
  const {...rest} = props;
  const scroll = useWindowScroll(100);
  const isMount = useIsMount();
  const [isContact, setContact] = useContactForm();
  const openContact = useCallback(() => setContact(true), []);
  const scrollUp = useCallback(() => scrollTo(), []);
  const router = useRouter();

  return (
    <Tickets {...rest}>
      {router.asPath === '/contact' ? null : (
        <Ticket
          color="red"
          data-test="ticket-contact"
          icon={faMessages}
          show={!isContact}
          title="Contact us."
          onClick={openContact} />
      )}

      <Ticket
        color="teal"
        data-test="ticket-scroll"
        icon={faAnglesUp}
        show={!isMount && scroll.y > 0}
        title="Scroll to top."
        onClick={scrollUp} />
    </Tickets>
  );
}

// styles

const Tickets = styled('section')`
position: fixed;
bottom: 0;
right: 0;
padding-right: 1.5rem;
z-index: var(--z-tickets);
display: flex;
flex-flow: row-reverse nowrap;
gap: 1rem;
`;
