// import

import type {PropsWithChildren} from 'react';

import {createContext, useContext} from 'react';

import {theme} from './theme';

// context

const ThemeContext = createContext(theme);
export const useTheme = () => useContext(ThemeContext);

export function ThemeProvider(props: PropsWithChildren<unknown>) {
  const {children} = props;

  return (
    <ThemeContext.Provider value={theme}>
      {children}
    </ThemeContext.Provider>
  );
}
