// import

import {styled} from '@linaria/react';

// styles

export const AppContent = styled('main')`
background-color: var(--white);
margin-top: var(--layout-headerHeight);
min-height: calc(100vh - var(--layout-headerHeight) - var(--layout-footerSepHeight));
position: relative;
width: 100%;
z-index: 1;
`;
