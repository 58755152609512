// import

import type {PropsWithChildren, RefObject, ReactNode} from 'react';

import constate from 'constate';
import {useMemo, useRef} from 'react';
import {createPortal} from 'react-dom';

// types

type ContextProps = {
  modalsRef: RefObject<HTMLElement | null>;
};

type UseModalRes = {
  createModal: (node: ReactNode) => ReturnType<typeof createPortal> | null;
};

// context

function ModalsContext({modalsRef}: ContextProps): UseModalRes {
  return useMemo(() => ({
    createModal(node: ReactNode) {
      return modalsRef.current ?
        createPortal(node, modalsRef.current) :
        null;
    },
  }), []);
}

const [Provider, useModals] = constate(ModalsContext);

// component

export function ModalsProvider(props: PropsWithChildren<unknown>) {
  const {children, ...rest} = props;
  const modalsRef = useRef<HTMLElement>(null);

  return (
    <Provider {...rest} modalsRef={modalsRef}>
      {children}
      <section ref={modalsRef} id="modals" />
    </Provider>
  );
}

// export

export {useModals};
