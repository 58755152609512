// import

import type {HTMLAttributes} from 'react';

import {css} from '@linaria/core';
import {useRef} from 'react';

import {cram} from '@arc/rambo';

import {Message} from './Message';
import {useMessageList} from './useMessageList';

// types

type Props = HTMLAttributes<HTMLElement>;

// component

export function MessageList(props: Props) {
  const {className, ...rest} = props;
  const list = useMessageList();
  const refs = useRef<Record<string, {current: HTMLDivElement | null}>>({});

  return (
    <aside {...rest} className={cram(listCls, className)}>
      {list.map((msg) => {
        // eslint-disable-next-line no-multi-assign
        const nodeRef = refs.current[msg.key] ??= {current: null};

        return (
          <Message
            key={msg.key}
            className={msgCls}
            forwardRef={nodeRef}
            message={msg} />
        );
      })}
    </aside>
  );
}

// styles

const listCls = css`
position: fixed;
top: var(--layout-headerHeight);
right: 0;
bottom: 0;
padding: 1.5rem;
width: var(--bp-xs);
user-select: none;
pointer-events: none;
z-index: var(--z-messages);
`;

const msgCls = css`
margin-bottom: 1rem;
`;
