// import

import {normalizeTheme} from './normalizeTheme';

// export

export const theme = normalizeTheme({
  layout: {
    headerHeight: '4.5rem',
    footerSepHeight: '4.5rem',
  },

  z: {
    tickets: 300,
    messages: 700,
    modals: 1000,
    header: 3000,
    contact: 7000,
    loading: 10000,
    auth: 50000,
  },

  font: {
    serif: 'serif',
    sans: 'sans-serif',
    mono: 'FiraCode-Retina, Fira Code, Roboto Mono, Menlo, Monaco, monospace',

    logo: 'Bungee, sans-serif',
    head: 'Bitter, Roboto Slab, serif',
    body: 'Rubik, Roboto, Lato, Open Sans, sans-serif',
  },

  color: {
    // palette alpha
    black: '#191a1a',
    white: '#f6f8f9',

    // palette lightness
    gray: '#7e8081',
    blue: '#3aa8c3',
    purple: '#98576e',
    yellow: '#ffcd67',
    orange: '#f8a847',
    green: '#4d995c',
    red: '#e76f51',

    prussia: '#2a7a8d',
  },
});
