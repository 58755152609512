// import

import type {IconDefinition} from '@fortawesome/fontawesome-common-types';
import type {MouseEventHandler, ComponentProps} from 'react';

import {styled} from '@linaria/react';

import {cram} from '@arc/rambo';

import {Icon} from 'modules/Icon';

// types

type Props = ComponentProps<typeof Ticket> & {
  icon: IconDefinition;
  onClick: MouseEventHandler<HTMLButtonElement>;
  show?: boolean;
  title: string;
  color?: 'black' | 'blue' | 'gray' | 'orange' | 'red' | 'teal' | 'yellow';
};

// component

export function AppTicket(props: Props) {
  const {title, onClick, color = 'gray', className, show, icon, ...rest} = props;

  return (
    <Ticket
      {...rest}
      aria-label={title}
      className={cram(className, color, {show})}
      type="button"
      onClick={onClick}>
      <Icon icon={icon} size="1.5rem" title={title} />
    </Ticket>
  );
}

// styles

const Ticket = styled('button')`
position: relative;
cursor: pointer;
bottom: -1.125rem;
border: 0;
border-radius: 0.25rem 0.25rem 0 0;
padding: 0.625rem 0.875rem 1.825rem;
background-color: var(--white);
box-shadow: 0 0 0.25rem var(--black-50);
transition: transform 100ms ease-in-out;
transform: translateY(3.25rem);

/* svg { width: 1.5rem; } */

&:hover {
  transform: translateY(3rem);
}

&.show {
  transform: translateY(0);

  &:hover {
    transform: translateY(-1rem);
  }
}

&.teal {
  color: var(--blue);
}

&.blue {
  color: var(--prussia);
}

&.red {
  color: var(--red);
}

&.orange {
  color: var(--orange);
}

&.yellow {
  color: var(--yellow);
}

&.gray {
  color: var(--gray-50);
}

&.black {
  color: var(--black);
}
`;
