// import

import type {GimmeData} from '@arc/gimme';

import {createGimmeData} from '@arc/gimme';

// export

export const hubApi: GimmeData = createGimmeData({
  baseUrl: `${process.env.RESOLVED_URL}/api`,
  response: 'json',
  useCors: true,
  useJson: true,
  sameOrigin: true,
});
