// import

import constate from 'constate';
import {useCallback, useState} from 'react';

// types

type Props = {value?: boolean};
type Returns = [number | null, (v: boolean) => void];

// fns

const toState = (v?: boolean) => {
  return v ? Date.now() : null;
};

// context

function useContactFormContext(props: Props): Returns {
  const [state, setState] = useState(toState(props.value));
  const trigger = useCallback((val: boolean) => setState(toState(val)), []);

  return [state, trigger];
}

export const [
  ContactFormProvider,
  useContactForm,
  useContactFormState,
  useContactFormTrigger,
] = constate(
  useContactFormContext,
  (res) => res,
  (res) => res[0],
  (res) => res[1],
);
