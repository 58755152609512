// import

import {styled} from '@linaria/react';
import {useState} from 'react';

import {cram} from '@arc/rambo';

import {useRouteChangeComplete, useRouteChangeStart} from './Router';

// component

// TODO: Extract LoadingView and abstract content from logic

export function LoadingView() {
  const [isLoading, setLoading] = useState(false);

  useRouteChangeStart(() => setLoading(true));
  useRouteChangeComplete(() => setLoading(false));

  return (
    <Loading className={cram(isLoading && 'loading')} data-test="app-loader">
      <div className="spinner s1" />
      <div className="spinner s2" />
      <div className="spinner s3" />
    </Loading>
  );
}

// styles

const Loading = styled('aside')`
position: fixed;
z-index: var(--z-loading);
top: 0;
left: 0;
right: 0;
bottom: 0;
display: flex;
flex-flow: row nowrap;
justify-content: center;
align-items: center;
background: var(--white);
opacity: 0;
user-select: none;
pointer-events: none;
transition: opacity 0.1s ease-in-out;
gap: 1rem;

& .spinner {
  flex: 0 1 auto;
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  animation: none;
  transform: scale(0);
}

&.loading {
  pointer-events: all;
  opacity: 1;

  & .spinner {
    animation: anim 2.0s infinite ease-in-out;
  }

  & .s1 {
    background-color: var(--yellow);
    animation-delay: 0s;
  }

  & .s2 {
    background-color: var(--orange);
    animation-delay: 0.666s;
  }

  & .s3 {
    background-color: var(--red);
    animation-delay: 1.333s;
  }
}

@keyframes anim {
  0% {
    transform: scale(0);
  }

  99% {
    transform: scale(1.0);
    opacity: 0;
  }

  100% {
    transform: scale(1.0);
    opacity: 0;
  }
}
`;
