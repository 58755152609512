// import

import type {ComponentProps, ElementType, PropsWithChildren, Ref} from 'react';

import {css} from '@linaria/core';
import {forwardRef} from 'react';

import {cram} from '@arc/rambo';

import {useRouteCapture} from 'modules/Router';

import block from './styles/TypeBlockStyles';
import button from './styles/TypeButtonStyles';
import code from './styles/TypeCodeStyles';
import form from './styles/TypeFormStyles';
import heading from './styles/TypeHeadingStyles';
import inline from './styles/TypeInlineStyles';
import link from './styles/TypeLinkStyles';
import list from './styles/TypeListStyles';
import media from './styles/TypeMediaStyles';
import table from './styles/TypeTableStyles';

// types

type Props = ComponentProps<any> & PropsWithChildren<{
  as?: ElementType;
  html?: string;
  className?: string;
  trim?: boolean | 'end' | 'top';
}>;

// vars

const typeCls = [
  'Type',
  block,
  button,
  code,
  form,
  heading,
  inline,
  link,
  list,
  media,
  table,
];

// component

export const Type = forwardRef((props: Props, ref: Ref<HTMLElement>) => {
  const {
    html, children, className,
    as: El = 'div', trim = true,
    ...rest
  } = props;

  const content = html ?
    {dangerouslySetInnerHTML: {__html: html}} :
    typeof children === 'string' ?
      {dangerouslySetInnerHTML: {__html: children}} :
      {children};

  return (
    <El
      {...rest}
      ref={ref}
      className={cram(typeCls, trimCls, className, {
        [trimTopCls]: trim && trim !== 'end',
        [trimEndCls]: trim && trim !== 'top',
      })}
      {...content} />
  );
});

export function TypeCapture(props: Props) {
  const captureRef = useRouteCapture();

  return (
    <Type {...props} ref={captureRef} />
  );
}

// styles

const trimCls = css`
margin-block: 0;
`;

const trimTopCls = css`
> :first-child { margin-top: 0; }
`;

const trimEndCls = css`
> :last-child { margin-bottom: 0; }
`;

