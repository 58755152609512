// import

import type {ComponentProps, MouseEvent} from 'react';

import {useContactFormTrigger} from 'modules/Contact';
import {Link} from 'modules/Link';

// types

type Props = ComponentProps<typeof Link>;

// component

export function AppLink(props: Props) {
  const {href, onClick, ...rest} = props;
  const toggleContact = useContactFormTrigger();
  const newProps = href === '/contact' ? {
    onClick(e: MouseEvent<HTMLAnchorElement>) {
      toggleContact(true);
      onClick?.(e);
    },
  } : {href, onClick};

  return (
    <Link {...rest} {...newProps} />
  );
}
