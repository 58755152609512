// import

import type {LinkGrid, LinkGridGroup} from '@arc/hub-model';
import type {ComponentProps} from 'react';

import {css} from '@linaria/core';
import {useMemo} from 'react';

import {cram} from '@arc/rambo';

import {AppLink} from 'modules/App/AppLink';
import {Type} from 'modules/Type';

// types

type Props = ComponentProps<'nav'> & {
  grid: LinkGrid;
};

type NestedGrid = Array<LinkGridGroup & {
  items: Array<Exclude<LinkGrid[number], LinkGridGroup>>;
}>;

// hooks

export function useLinkGrid(grid: LinkGrid) {
  return useMemo(() => {
    return grid.reduce<NestedGrid>((acc, item, i, arr) => {
      const activeGroup = acc[acc.length - 1];

      if (i === 0 && item.type !== 'group') {
        throw new Error('Link grid must begin with "group."');
      }

      if (i === arr.length - 1 && item.type === 'group') {
        throw new Error('Link grid must not end with "group."');
      }

      if (item.type === 'group') {
        if (activeGroup?.items.length === 0) {
          throw new Error('Link grid "group" must contain items.');
        }

        acc.push({...item, items: []});
      } else {
        activeGroup.items.push(item);
      }

      return acc;
    }, []);
  }, [grid]);
}

// component

export function FooterLinkGrid(props: Props) {
  const {grid, className, ...rest} = props;
  const parsed = useLinkGrid(grid);

  return (
    <nav {...rest} className={cram(navCls, className)}>
      {parsed.map((group, key) => (
        <div key={key} className={groupCls} style={{gridRow: `span ${group.items.length + 1 + Number(!!group.blurbMark)}`}}>
          <Type as="h2" className={titleCls}>{group.text}</Type>

          {group.blurbMark ? (
            <Type className={blurbCls}>{group.blurbMark}</Type>
          ) : null}

          {group.items.map((item) => (
            <AppLink key={item.text} className={linkCls} href={item.link}>
              {item.text}
            </AppLink>
          ))}
        </div>
      ))}
    </nav>
  );
}

// styles

const navCls = css`
display: grid;
grid-template-columns: repeat(auto-fit, minmax(9rem, 1fr));
gap: 3rem;
`;

const groupCls = css`
display: grid;
grid-auto-rows: auto;
align-content: start;
`;

const titleCls = css`
font-weight: 600;
font-family: var(--font-head);
letter-spacing: normal; 
line-height: 2rem;
font-size: 1.5rem;
text-decoration: underline var(--blue-80);
`;

const blurbCls = css`
font-size: 1rem;
line-height: 1.25rem;
margin-bottom: 0.75rem;

p {
  hyphens: none;
}
`;

const linkCls = css`
display: block;
white-space: nowrap;

&:hover {
  text-decoration: underline;
}
`;
