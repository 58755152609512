// import

import type {IconDefinition} from '@fortawesome/fontawesome-common-types';
import type {ComponentProps, PropsWithChildren} from 'react';

import {styled} from '@linaria/react';
import {forwardRef} from 'react';

import {ensureArray} from '@arc/rambo';

// types

type Props = ComponentProps<'svg'> & PropsWithChildren<{
  icon: IconDefinition;
  title?: string;

  size?: number | string;
  width?: number | string;
  height?: number | string;

  flip?: boolean;
  mirror?: boolean;
  colors?: string[] | string;
}>;

// fns

const getTransform = (mirror?: boolean, flip?: boolean) => {
  return mirror || flip ? `scale(${mirror ? -1 : 1}, ${flip ? -1 : 1})` : undefined;
};

// component

export const Icon = forwardRef<SVGSVGElement, Props>((props: Props, ref) => {
  const {icon, size, width, height, title, children, flip, mirror, colors, ...rest} = props;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {icon: [w, h, lig, uni, path]} = icon;
  const color = ensureArray(colors ?? []);

  return (
    <Svg
      {...rest}
      ref={ref}
      height={height ?? (width ? undefined : size ?? '1em')}
      transform={getTransform(mirror, flip)}
      viewBox={`0 0 ${w} ${h}`}
      width={width ?? (height ? undefined : size ?? '1em')}>
      {title && (<title>{title}</title>)}
      {ensureArray(path).map((draw, i) => (
        <path
          key={i}
          d={draw}
          style={{fill: color[i % color.length] ?? 'currentColor'}} />
      ))}
    </Svg>
  );
});

// styles

const Svg = styled('svg')`
display: inline-block;
overflow: visible;
text-align: center;
font-size: inherit;
`;
