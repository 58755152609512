// import

import {css} from '@linaria/core';

import {useSettings} from 'modules/App';
import {ZigZag} from 'modules/Svg';

import {AppFooterLegal} from './AppFooterLegal';
import {FooterLinkGrid} from './FooterLinkGrid';

// component

export function AppFooter() {
  const {nav: {footerGrid}} = useSettings();

  return (
    <footer className={footerCls} data-test="app-footer">
      <ZigZag className="zig" color="blue" id="footer" />

      <div className={contentCls}>
        <div className={flexCls}>
          <div className={innerCls}>
            <FooterLinkGrid grid={footerGrid} />
          </div>

          <div className={ruleCls} />

          <div className={innerCls}>
            <AppFooterLegal />
          </div>
        </div>
      </div>
    </footer>
  );
}

// styles

const footerCls = css`
position: relative;
z-index: 1;
`;

const contentCls = css`
position: relative;
padding: 6rem 3rem;
color: var(--white);
background-color: var(--blue);

&::before {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: -0.125rem;
  height: 0.125rem;
  background-color: var(--blue);
  z-index: -1;
}
`;

const flexCls = css`
max-width: var(--bp-l);
margin-inline: auto;
display: flex;
flex-flow: column;
gap: 3rem;
`;

const innerCls = css``;

const ruleCls = css`
margin: 0 auto;
width: 75%;
height: var(--rpx);
background:
  linear-gradient(
    90deg,
    rgba(var(--rgb-blue), 0),
    var(--blue-70),
    var(--blue-70),
    rgba(var(--rgb-blue), 0)
  );
`;
