// import

import type {UserContext} from '@auth0/nextjs-auth0/dist/frontend';

import {useUser} from '@auth0/nextjs-auth0';
import {useConfig} from '@auth0/nextjs-auth0/dist/frontend';
import {useRouter} from 'next/router';
import {useEffect} from 'react';

// vars

const returnToRx = /^.+\?returnTo=/u;

// hooks

export function useAuth({returnTo}: {returnTo?: string} = {}): UserContext {
  const router = useRouter();
  returnTo ??= router.asPath.replace(returnToRx, '');
  const {loginUrl} = useConfig();
  const auth0 = useUser();
  const {user, error, isLoading} = auth0;

  useEffect(() => {
    if ((user && !error) || isLoading) {
      return;
    }

    window.location.assign(`${loginUrl}?returnTo=${returnTo}`);
  }, [user, error, isLoading]);

  return auth0;
}
