// import

import type {Fields} from './ContactForm';
import type {ComponentProps} from 'react';

import {faMessages, faXmark} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';
import {styled} from '@linaria/react';
import {useCallback, useState} from 'react';

import {cram, isObj} from '@arc/rambo';

import {Icon} from 'modules/Icon';
import {Link} from 'modules/Link';
import {useRouteChangeStart} from 'modules/Router';

import {ContactForm} from './ContactForm';
import {useContactForm} from './useContactForm';

// types

type Props = ComponentProps<typeof Section>;

// fns

const isFields = (val: unknown): val is Fields => {
  return isObj(val) && 'name' in val && 'email' in val && 'message' in val;
};

// component

export function ContactModal(props: Props) {
  const {className, ...rest} = props;
  const [isActive, setActive] = useContactForm();
  const [defaultValues, saveState] = useState<Fields>();
  const closeContact = useCallback((values?: unknown) => {
    if (isFields(values)) {
      saveState(values);
    }

    setActive(false);
  }, []);

  useRouteChangeStart(() => setActive(false));

  return (
    <Section
      {...rest}
      className={cram({active: isActive}, className)}
      data-test="contact-form">
      <header>
        <Icon className="icon" icon={faMessages} />
        <div className="title">Hey, author!</div>
        <Link
          aria-label="Close contact form."
          className={cram('close')}
          onClick={closeContact}>
          <Icon icon={faXmark} />
        </Link>
      </header>

      <ContactForm
        className={formCls}
        defaultValues={defaultValues}
        onSubmit={closeContact} />
    </Section>
  );
}

// styles

const Section = styled('section')`
bottom: 0;
filter: drop-shadow(0 0 0.25rem var(--black-50));
width: calc(100vw - 3rem);
max-width: var(--bp-s);
overflow: hidden;
position: fixed;
right: 1.5rem;
z-index: var(--z-contact);
transition: transform 250ms ease-in-out;
transform: translateY(105%);

&.active {
  transform: translateY(0);
}

header {
  background-color: var(--yellow);
  position: relative;
  display: flex;
  line-height: 1.5rem;
  user-select: none;
  padding-top: 3.5rem;
  clip-path:
    polygon(
      calc(100% + 1px) 3.5rem,
      calc(100% + 1px) 100%,
      calc(0% - 1px) 100%,
      calc(0% - 1px) 3.5rem,
      25% 2rem,
      25% 1.5rem,
      50% 0,
      75% 1.5rem,
      75% 2rem
    );
}

.icon {
  position: absolute;
  top: 0.5rem;
  width: 2rem;
  height: 2rem;
  left: calc(50% - 1rem);
}

.title {
  flex: 1 0 auto;
  font-size: 1.125rem;
  margin: 0;
  padding: 0.5rem 1rem;
  font-weight: 400;
  letter-spacing: 0;
}

.close {
  padding: 0.5rem;
  transition: color 100ms linear;

  &:hover {
    color: var(--red);
  }

  svg { width: 1.5rem; }
}
`;

const formCls = css`
padding: 1rem;
`;
