// import

import type {ComponentProps} from 'react';

import {styled} from '@linaria/react';
import {useCallback} from 'react';
import {useToggle} from 'react-use';

import {Link} from 'modules/Link';
import {Modal} from 'modules/Modals';
import {Type} from 'modules/Type';

import LogoIcon from '../../public/logo/icon.svg';
import LogoText from '../../public/logo/text.svg';

import {AppLink} from './AppLink';
import {BurgerButton} from './BurgerButton';
import {useSettings} from './useSettings';

// types

type Props = ComponentProps<typeof Header> & {
  show?: boolean;
};

// vars

const localUrl = process.env.APP_LOCAL_URL;

// component

export function AppHeader(props: Props) {
  const {show, ...rest} = props;
  const {
    biz: {niceName},
    nav: {headerLinks, menuLinks},
  } = useSettings();
  const [isOpen, toggleOpen] = useToggle(false);

  const toggleMenu = useCallback(() => toggleOpen(), []);

  const closeMenu = useCallback((e: any) => {
    let targ = e?.target ?? undefined;

    while (targ) {
      if (targ?.className?.includes?.('burger')) {
        return;
      }

      targ = targ.parentNode;
    }

    toggleOpen(false);
  }, []);

  return (
    <Header {...rest} data-test="app-header" onClick={closeMenu}>
      <Link aria-label={niceName} className="logo" href="/">
        <LogoIcon className="icon" tabIndex={0} />
        <LogoText className="text" tabIndex={0} />
      </Link>

      <nav className="nav">
        {headerLinks.map(({link, text}) => (
          <StyledLink key={text} href={link}>
            {text}
          </StyledLink>
        ))}
      </nav>

      <Type className="act">
        <a
          className="button green"
          href={`${localUrl.replace('www.', 'app.')}/site`}>
          Sign In
        </a>
      </Type>

      <BurgerButton
        className="burger"
        open={isOpen}
        onClick={toggleMenu} />

      <Menu close={closeMenu} open={isOpen}>
        <nav>
          <header>Explore</header>

          {menuLinks.map(({link, text}) => (
            <StyledLink key={text} href={link} onClick={closeMenu}>
              {text}
            </StyledLink>
          ))}
        </nav>
      </Menu>
    </Header>
  );
}

// styles

const Header = styled('header')`
position: fixed;
top: 0;
width: 100%;
height: var(--layout-headerHeight);
z-index: var(--z-header);
user-select: none;

display: flex;
flex-flow: nowrap;
gap: 1.25rem;
padding: 0 1.5rem;

background-color: var(--white);
box-shadow: 0 0 1.5rem var(--black-50);

.logo {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.icon {
  height: 2.5rem;
  width: auto;
  display: inline-block;
}

.text {
  height: 2.25rem;
  width: auto;
}

.nav {
  flex: 1 1 auto;
  display: flex;
  flex-flow: wrap;
  align-items: center;
  justify-content: flex-end;
  gap: 0.125rem;
  overflow: hidden;
  height: var(--layout-headerHeight);
}

.act {
  flex: 0 0 auto;
  align-self: center;
}

& .burger {
  flex: 0 0 auto;
  align-self: center;
  display: none;
}

@media (max-width: 62.99875em) {
  .nav a:nth-child(n + 4) { display: none; }
}

@media (max-width: 53.99875em) {
  .nav a { display: none; }
  .burger { display: flex; }
}

@media (max-width: 44.99875em) {
  padding: 0 0.75rem;
  .logo { gap: 0.5rem; }
}

@media (max-width: 35.99875em) {
  .text { display: none; }
}
`;

const Menu = styled(Modal)`
nav {
  position: absolute;
  top: calc(1rem + var(--layout-headerHeight));
  right: 1rem;
  user-select: none;

  display: flex;
  flex-flow: column;
  gap: 0.25rem;

  min-width: 9rem;
  max-width: calc(100vw - 3rem);
  padding: 1rem 0.75rem 0.75rem;

  background-color: var(--white);
  border-radius: 0.25rem;
  box-shadow: 0 0.25rem 0.75rem 0.25rem var(--black-25);
  text-align: center;

  &::after {
    content: "";
    display: block;
    position: absolute;
    top: -0.5rem;
    right: 1rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(45deg);
    background-color: var(--white);
  }
}

header {
  color: var(--gray-50);
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.5rem;
  letter-spacing: normal;
  text-transform: uppercase;
}
`;

const StyledLink = styled(AppLink)`
flex: 0 0 auto;
position: relative;
padding: 0 0.75rem;
transition: color 100ms ease-in-out;
font-size: 1.125rem;
font-variation-settings: "wght" 350;
z-index: 1;

&::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--red);
  transition: opacity 100ms ease-in-out;
  opacity: 0;
  z-index: -1;
  border-radius: 0.5rem;
}

&:hover {
  color: var(--white);
  &::before { opacity: 1; }
}
`;
