// import

import type {MessageOut} from './useMessageList';
import type {HTMLAttributes, MutableRefObject} from 'react';

import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {css} from '@linaria/core';
import {useCallback} from 'react';

import {cram} from '@arc/rambo';
import {useTimeoutEffect} from '@arc/react-hooks';

import {Icon} from 'modules/Icon';
import {Type} from 'modules/Type';

import {useCloseMessage} from './useMessageList';

// types

type Props = HTMLAttributes<HTMLElement> & {
  forwardRef: MutableRefObject<HTMLDivElement | null>;
  message: MessageOut;
};

// component

export function Message(props: Props) {
  const {message, forwardRef: ref, className} = props;
  const closeMessage = useCloseMessage();
  const closeThis = useCallback(() => closeMessage(message), []);

  useTimeoutEffect(closeThis, message.timeout);

  return (
    <div ref={ref} className={cram(messageCls, message.color, className)}>
      <Type className={textCls}>{message.text}</Type>

      <button
        aria-label="Close"
        className={buttonCls}
        type="button"
        onClick={closeThis}>
        <Icon icon={faXmark} />
      </button>
    </div>
  );
}

// styles

const messageCls = css`
border-radius: 0.125rem;
border-width: 0 0 0 0.25rem;
border-style: solid;
padding: 0.5rem;
width: 100%;
display: flex;
flex-flow: row nowrap;
gap: 0.5rem;
pointer-events: all;
transform-origin: 100% 50%;
animation: anim 250ms ease-in-out;

&.green {
  background-color: var(--green-85);
  border-left-color: var(--green-60);
  box-shadow:
    0 0 0.125rem rgba(var(--rgb-green), 0.25),
    0 0 0.125rem var(--black-25);
}

&.blue {
  background-color: var(--blue-90);
  border-left-color: var(--blue-60);
  box-shadow:
    0 0 0.125rem rgba(var(--rgb-blue), 0.25),
    0 0 0.125rem var(--black-25);
}

&.yellow {
  background-color: var(--yellow-100);
  border-left-color: var(--yellow-65);
  box-shadow:
    0 0 0.125rem rgba(var(--rgb-yellow), 0.25),
    0 0 0.125rem var(--black-25);
}

&.orange {
  background-color: var(--orange-90);
  border-left-color: var(--orange-60);
  box-shadow:
    0 0 0.125rem rgba(var(--rgb-orange), 0.25),
    0 0 0.125rem var(--black-25);
}

&.red {
  background-color: var(--red-90);
  border-left-color: var(--red-60);
  box-shadow:
    0 0 0.125rem rgba(var(--rgb-red), 0.25),
    0 0 0.125rem var(--black-25);
}

@keyframes anim {
  0% { transform: translateX(27rem) scaleY(0); }
  50% { transform: translateX(27rem) scaleY(0.5); }
  100% { transform: translateX(0) scaleY(1); }
}
`;

const textCls = css`
flex: 1 1 auto;
font-size: 1rem;
line-height: 1.5rem;
`;

const buttonCls = css`
border: none;
background-color: transparent;
height: 1.5rem;
line-height: 1.5rem;
padding: 0;
display: flex;
justify-content: center;
align-items: center;
cursor: pointer;
transition: transform 0.1s ease-in-out;

svg { width: 1.25rem; }

&:hover { transform: scale(1.25); }
`;
