// import

import type {RouterEvent} from 'next/router';

import Router from 'next/router';
import {useEffect} from 'react';
import {useLatest} from 'react-use';

// type

type RouteErr = Error & {cancelled?: boolean};
type Opts = {shallow?: boolean};

type ResHandler = (url: string, opts: Opts, err: Error) => void;
type ErrHandler = (err: RouteErr, url: string, opts: Opts) => void;

type EventHandler<E extends RouterEvent> =
  E extends 'routeChangeError' ? ErrHandler : ResHandler;

// export

export function useRouteEvent<E extends RouterEvent>(
  event: E,
  handler?: EventHandler<E> | null,
) {
  const handlerRef = useLatest(handler);

  return useEffect(() => {
    const onEvent: EventHandler<E> = (a: any, b: any, c: any) => {
      if (handlerRef.current) {
        handlerRef.current(a, b, c);
      }
    };

    Router.events.on(event, onEvent);

    return () => Router.events.off(event, onEvent);
  }, [event]);
}

export function useRouteChangeStart(handler?: ResHandler | null) {
  return useRouteEvent('routeChangeStart', handler);
}

export function useRouteChangeComplete(handler?: ResHandler | null) {
  return useRouteEvent('routeChangeComplete', handler);
}

export function useRouteChangeError(handler?: ErrHandler | null) {
  return useRouteEvent('routeChangeError', handler);
}

export function useHashChangeStart(handler?: ResHandler | null) {
  return useRouteEvent('hashChangeStart', handler);
}

export function useHashChangeComplete(handler?: ResHandler | null) {
  return useRouteEvent('hashChangeComplete', handler);
}

export function useBeforeHistoryChange(handler?: ResHandler | null) {
  return useRouteEvent('beforeHistoryChange', handler);
}
