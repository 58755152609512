// import

import '@csstools/normalize.css';
import 'styles/globals.css';
import 'styles/vars.css';

import type {UserProfile} from '@auth0/nextjs-auth0';
import type {Settings} from 'libs/model';
import type {WithLayout} from 'modules/Layout';
import type {NextComponentType} from 'next';
import type {AppProps} from 'next/app';

import Head from 'next/head';
import {Fragment} from 'react';

import {isVercelPreview} from '@arc/env';

import {SettingsProvider} from 'modules/App';
import {UserProvider, PageAuth} from 'modules/Auth';
import {ContactModal, ContactFormProvider} from 'modules/Contact';
import {getDefaultLayout} from 'modules/Layout';
import {LoadingView} from 'modules/LoadingView';
import {MessageList, MessageListProvider} from 'modules/MessageList';
import {ModalsProvider} from 'modules/Modals';
import {AppSeo} from 'modules/Seo';
import {ThemeProvider} from 'modules/Theme';
import settings from 'preval/settings.json';

// types

type Props = AppProps & {
  Component: NextComponentType & {withLayout?: WithLayout};
  pageProps: {
    [index: string]: unknown;
    user?: UserProfile;
  };
};

// vars

const MaybePageAuth = isVercelPreview ? PageAuth : Fragment;

const postFeeds = ['rss', 'atom', 'json'].map((fmt) => (
  <link
    key={fmt}
    href={`/feed/post.${fmt}`}
    rel="alternate"
    title={`Blog Posts ⬩ SiteArcade ⬩ ${fmt.split('.')[0].toUpperCase()}`}
    type={`application/${fmt.replace('.', '+')}`} />
));

// component

export default function App(props: Props) {
  const {Component, pageProps: {user, ...pageProps}} = props;
  const withLayout = Component.withLayout || getDefaultLayout();

  return (
    <UserProvider user={user}>
      <SettingsProvider value={settings as unknown as Settings}>
        <Head>
          {/* meta */}
          <meta content="SiteArcade" name="application-name" />
          <meta content="width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=3" name="viewport" />

          {/* feeds */}
          {postFeeds}
        </Head>

        <AppSeo />

        <ThemeProvider>
          <MaybePageAuth>
            <MessageListProvider>
              <ContactFormProvider>
                <ModalsProvider>
                  {withLayout(
                    <Component {...pageProps} />,
                  )}
                </ModalsProvider>

                <ContactModal />
              </ContactFormProvider>

              <MessageList />
            </MessageListProvider>

            <LoadingView />
          </MaybePageAuth>
        </ThemeProvider>
      </SettingsProvider>
    </UserProvider>
  );
}

