// import

import type {Infer} from 'superstruct';

import constate from 'constate';
import {useMemo, useState} from 'react';
import {enums} from 'superstruct';

import {isStr} from '@arc/rambo';
import {getNanoId} from '@arc/uid';

// types

const MessageColor = enums([
  'blue',
  'yellow',
  'orange',
  'red',
  'green',
]);

export type MessageIn = string | {
  key?: string;
  timeout?: number;
  color?: Infer<typeof MessageColor>;
  text: string;
};

export type MessageOut = {
  key: string;
  timeout: number;
  color: Infer<typeof MessageColor>;
  text: string;
};

// fns

const cleanMessage = (msg: MessageIn): MessageOut => ({
  key: getNanoId(),
  color: 'blue',
  timeout: 1000 * 5,
  ...isStr(msg) ? {text: msg} : msg,
});

// context

const MessagesListContext = () => {
  const [list, set] = useState<MessageOut[]>([]);

  // TODO: Request stored messages from server and append when they arrive.

  const [postMessage, closeMessage] = useMemo(() => [
    (msg: MessageIn) => set((arr) => [...arr, cleanMessage(msg)]),
    (msg: MessageOut) => set((arr) => arr.filter(({key}) => key !== msg.key)),
  ], []);

  return {list, postMessage, closeMessage};
};

export const [
  MessageListProvider,
  useMessageList,
  usePostMessage,
  useCloseMessage,
] = constate(
  MessagesListContext,
  (res) => res.list,
  (res) => res.postMessage,
  (res) => res.closeMessage,
);
