// import

import type {FrameProps} from './ModalFrame';
import type {createPortal} from 'react-dom';

import {useRef} from 'react';

import {ModalFrame} from './ModalFrame';
import {useModals} from './useModals';

// types

type Props = Omit<FrameProps, 'frameRef'> & {
  open?: boolean;
  className?: string;
};

// component

export function Modal(props: Props): ReturnType<typeof createPortal> | null {
  const {open = true, ...rest} = props;
  const {createModal} = useModals();
  const ref = useRef<HTMLElement>(null);

  return open ? createModal((
    <ModalFrame {...rest} frameRef={ref} />
  )) : null;
}
