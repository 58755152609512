// import

import type {MouseEvent, Ref} from 'react';

import Router from 'next/router';

import {useEvents} from '@arc/react-hooks';

import {useContactFormTrigger} from 'modules/Contact';

// vars

const arcRx = /^(?:(?:https?:\/\/)?(?:w{3}\.(?:\w+\.)*)?sitearcade\.com\/?|\/)/u;

// fns

const ignoreEvent = (ev: MouseEvent) => {
  switch (true) {
    case ev.defaultPrevented:
      return true;
    case ev.button && ev.button !== 0:
      return true;
    case ev.metaKey:
      return true;
    case ev.ctrlKey:
      return true;
    case ev.shiftKey:
      return true;

    default:
      return false;
  }
};

const findNearestLink = (
  el?: HTMLAnchorElement | null,
): HTMLAnchorElement | null => {
  return el ? el.nodeName === 'A' ?
    el : findNearestLink(el.parentNode as null) : null;
};

const isAnchor = (el?: HTMLAnchorElement | null): el is HTMLAnchorElement => {
  switch (true) {
    case !el:
      return false;
    case !!el?.download:
      return false;
    case !el?.href:
      return false;
    case !arcRx.test(el?.href ?? ''):
      return false;

    default:
      return true;
  }
};

// export

export function useRouteCapture<T extends HTMLElement = HTMLElement>() {
  const openContact = useContactFormTrigger();

  return useEvents((ev) => {
    if (ignoreEvent(ev as unknown as MouseEvent)) {
      return;
    }

    const el = findNearestLink(ev.target as null);

    if (!isAnchor(el)) {
      return;
    }

    const href = el.href.replace(arcRx, '/');

    if (ev.type === 'mouseenter') {
      if (href.startsWith('/')) {
        Router.prefetch(href);
      }

      return;
    }

    el.blur();
    ev.preventDefault();

    if (href.startsWith('/contact')) {
      return openContact(true);
    }

    Router.push(href);
  }, ['click', 'keydown', 'mouseenter']) as Ref<T>;
}

